@font-face {
  font-family: Morrisons-Agenda;
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url(assets/fonts/MorrisonsAgenda-Light.woff) format("woff");
}
@font-face {
  font-family: Morrisons-Agenda;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(assets/fonts/MorrisonsAgenda-Medium.woff) format("woff");
}
@font-face {
  font-family: Morrisons-Agenda;
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(assets/fonts/MorrisonsAgenda-Semibold.woff) format("woff");
}
@font-face {
  font-family: Morrisons-Agenda;
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(assets/fonts/MorrisonsAgenda-Bold.woff) format("woff");
}

body {
  margin: 0;
  font-family: "Morrisons-Agenda", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Style the Google Recaptcha component to fit s*/
iframe {
  border: 0;
  transform: scale(0.95);
  transform-origin: 0 0;
}
